import * as web3 from "@solana/web3.js";
import * as splToken from "@solana/spl-token";
import bs58  from "bs58";

const solanaTokenAddress = process.env.REACT_APP_SOLANA_TOKEN_ADDRESS ?
    process.env.REACT_APP_SOLANA_TOKEN_ADDRESS : 'Gh9ZwEmdLJ8DscKNTkTqPbNwLNNBjuSzaG9Vp2KGtKJr';

export async function getAssociatedTokenAccount(connection: web3.Connection, publicKey: web3.PublicKey) {
    const filters: web3.GetProgramAccountsFilter[] = [
        {
            dataSize: 165,  //size of account (bytes)
        },
        {
            memcmp: {
                offset: 32,  //location of query in the account (bytes)
                bytes: publicKey.toString(),  // search criteria, a base58 encoded string
            },
        },
        {
            memcmp: {
                offset: 0,
                bytes: solanaTokenAddress
            }
        }
    ];
    const accounts = await connection.getParsedProgramAccounts(
        splToken.TOKEN_PROGRAM_ID,
        { filters: filters }
    );
    return accounts[0];
}

export async function getSolanaTokenBalance(connection: web3.Connection, publicKey: web3.PublicKey) {
    const account = await getAssociatedTokenAccount(connection, publicKey);
    const parsedAccountInfo: any = account.account.data;
    const tokenBalance: number = parsedAccountInfo["parsed"]["info"]["tokenAmount"]["uiAmount"];
    return tokenBalance;
}

export async function burn(connection: web3.Connection, sendTransaction: any, publicKey: web3.PublicKey, amount: number) {
    const account = await getAssociatedTokenAccount(connection, publicKey);
    const transaction = new web3.Transaction().add(
        splToken.Token.createBurnInstruction(
            splToken.TOKEN_PROGRAM_ID,
            new web3.PublicKey(solanaTokenAddress),
            account.pubkey,
            publicKey,
            [],
            amount * 1e2
        )
    )

    const {
        context: { slot: minContextSlot },
    } = await connection.getLatestBlockhashAndContext();

    try {
        const signature = await sendTransaction(transaction, connection, { maxRetries: 10, minContextSlot });
        const latestBlockHash = await connection.getLatestBlockhash();

        const response = await connection.confirmTransaction({
            blockhash: latestBlockHash.blockhash,
            lastValidBlockHeight: latestBlockHash.lastValidBlockHeight,
            signature: signature,
        });
        if (response.value.err === null) return signature;
    }
    catch (error) {
        return null;
    }
    return null;
}

export async function claim(signMessage: any, ethReceiver: string, solanaTxId: string) {
    const message = `I authorize ${ethReceiver} to claim the burnt tokens on ${solanaTxId} to ETH network`;
    const encodedMessage = new TextEncoder().encode(message);
    try {
    const signedMessage = await signMessage(encodedMessage);
    if (signedMessage) return bs58.encode(signedMessage);
    return null;
    }
    catch (error) {
        return null;
    }
}




import { MouseEventHandler, ReactNode, useState, useEffect } from "react";
import { FONTS } from "../../lib/theme";
import { sectionMarginBottom } from "../../constants/styleConstants";
import { getSolanaTokenBalance, burn, claim } from "../../services/solanaTokenActions";
import { Box, Grid, Stack, Typography, Button, TextField, Link, styled, CircularProgress } from "@mui/material";
import Web3 from "web3";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { sign } from "../../services/bridge";
import { claimEth } from "../../services/ethereumBridgeActions";

export type SwapBoxPropsType = {};

const BgBox: React.FC<{ children?: ReactNode, onClick?: MouseEventHandler<HTMLButtonElement> }> = ({ children, onClick }) => {
    return (
        <Button
            onClick={onClick}
            sx={{
                color: 'white',
                bgcolor: "rgba(0, 0, 0, 0.7)",
                border: "1px solid",
                borderImageSlice: 1,
                borderImageSource:
                    "linear-gradient(168.82deg, #1BB2DE 1.7%, rgba(155, 111, 238, 0) 27.12%, rgba(123, 127, 234, 0) 61.28%, #FB37FF 99.52%)",
                py: 1.5,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            {children}
        </Button>
    );
};

const StyledSmallTitle = styled(Typography)(() => ({
    fontSize: "0.9rem",
    fontFamily: FONTS.POPPINS,
    textAlign: 'center'
}));

const SwapBox: React.FC<SwapBoxPropsType> = () => {

    const [inputAmount, setInputAmount] = useState("");
    const [formErrors, setFormErrors] = useState({ inputAmount: "" });

    const [haveMetamask, sethaveMetamask] = useState(true);
    const [isMetamaskLoading, setIsMetamaskLoading] = useState(false);
    const [accountAddress, setAccountAddress] = useState('');
    const [isConnected, setIsConnected] = useState(false);
    const [solanaTokenBalance, setSolanaTokenBalance] = useState(0);
    const [burnInProgress, setBurnInPorgress] = useState(false);
    const [claimTxInProgress, setClaimTxInProgress] = useState(false);
    const [claimInProgress, setClaimInProgress] = useState(false);
    const [displaySuccess, setDisplaySuccess] = useState(false);
    const [displayError, setDisplayError] = useState("");
    const [displayInput, setDisplayInput] = useState(false);
    const [txSignature, setSignature] = useState("");
    const { ethereum } = window;
    const { publicKey, sendTransaction, signMessage } = useWallet()
    const { connection } = useConnection()
    let web3


    useEffect(() => {
        const checkMetamaskAvailability = async () => {
            if (window.ethereum) {
                sethaveMetamask(true);
                web3 = new Web3(window.ethereum)

                if (window.ethereum.networkVersion !== 1) {
                    try {
                        await window.ethereum.request({
                            method: 'wallet_switchEthereumChain',
                            params: [{ chainId: web3.utils.toHex(1) }]
                        });
                    } catch (err: any) {
                        // This error code indicates that the chain has not been added to MetaMask
                        if (err.code === 4902) {
                            await window.ethereum.request({
                                method: 'wallet_addEthereumChain',
                                params: [
                                    {
                                        chainName: process.env.REACT_APP_ETHEREUM_CHAIN_NAME ? process.env.REACT_APP_ETHEREUM_CHAIN_NAME : 'Rinkeby',
                                        chainId: web3.utils.toHex(process.env.REACT_APP_ETHEREUM_CHAIN_ID ? process.env.REACT_APP_ETHEREUM_CHAIN_ID : 4),
                                        nativeCurrency: { name: 'ETHEREUM', decimals: 18, symbol: 'ETH' },
                                        rpcUrls: [process.env.REACT_APP_ETHEREUM_RPC_PROVIDER ? process.env.REACT_APP_ETHEREUM_RPC_PROVIDER : 'https://rpc.ankr.com/eth_rinkeby']
                                    }
                                ]
                            });
                        }
                    }
                }
            }
            else {
                sethaveMetamask(false);
            }
        };
        checkMetamaskAvailability();
    }, []);

    useEffect(() => {
        const chekcSolanaTokenBalance = async () => {
            if (!publicKey) return;
            const solanaTokenBalance = await getSolanaTokenBalance(connection, publicKey);
            setSolanaTokenBalance(solanaTokenBalance);
        }
        chekcSolanaTokenBalance();
    })

    const connectWallet = async () => {
        try {
            if (!ethereum) {
                sethaveMetamask(false);
            }
            setIsMetamaskLoading(true);
            const accounts = await ethereum.request({
                method: 'eth_requestAccounts',
            });
            setAccountAddress(accounts[0]);
            setIsConnected(true);
            setDisplayInput(true);
            setIsMetamaskLoading(false);
        } catch (error) {
            setIsConnected(false);
            handleError("refresh");
        }
    };

    const handleBurn = async () => {
        if (!publicKey) {
            handleError("refresh");
            return;
        }
        setDisplayInput(false);
        setBurnInPorgress(true);

        const signature = await burn(connection, sendTransaction, publicKey, +inputAmount)
        if (signature === null) {
            setBurnInPorgress(false);
            handleError("refresh");
            return;
        }
        setSignature(signature);
        setBurnInPorgress(false);
        setClaimInProgress(true);
    }

    const handleClaim = async () => {
        setClaimInProgress(false);
        if (!publicKey) {
            handleError("burned");
            return;
        }
        setBurnInPorgress(true);
        const messageSignature = await claim(signMessage, accountAddress, txSignature);
        if (messageSignature === null) {
            setBurnInPorgress(false);
            handleError("burned");
            return;
        }
        const response = await sign(accountAddress, publicKey.toString(), messageSignature, txSignature);
        if (response === null) {
            setBurnInPorgress(false);
            handleError("burned");
            return;
        }
        setBurnInPorgress(false);
        setClaimTxInProgress(true);
        if (typeof response !== 'string') {
            const ethResponse = await claimEth(response);
            if(ethResponse == null) {
                setClaimTxInProgress(false);
                handleError("burned");
                return;
            }
            setClaimTxInProgress(false);
            handleSuccess();
        }
        else {
            setClaimInProgress(false);
            handleError("burned");
        }
    }


    const handleSuccess = async () => {
        setDisplaySuccess(true);
        setClaimTxInProgress(false);
    }

    const handleError = async (type: string) => {
        setDisplayError(type);
    }

    return (
        <Grid container>
            <Grid item xs={1} sm={1} md={2} />
            <Grid
                item xs={10} sm={10} md={8}
                sx={{
                    px: [5, 5, 12],
                    py: [3, 3, 7],
                    background: `rgba(0,0,0,0.3)`,
                    backgroundImage:
                        "linear-gradient(30.91deg, rgba(143, 60, 221, 0.10) 1.01%, rgba(0,0,0,0.3) 100%)",
                    border: "1px solid",
                    borderImageSlice: 1,
                    borderImageSource:
                        "linear-gradient(168.82deg, #FB37FF 1.7%, rgba(155, 111, 238, 0) 27.12%, rgba(123, 127, 234, 0) 61.28%, #1BB2DE 99.52%)",
                    backdropFilter: "blur(642.519px)",
                    borderRadius: "4px",
                    mb: sectionMarginBottom,
                }}
            >
                <Grid container sx={{ justifyContent: 'center' }}>
                    <Grid item xs={10} sm={12} md={12}>
                        <Stack spacing={2} sx={{ alignItems: 'center' }}>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    border: "1px solid",
                                    borderColor: "common.gray",
                                    borderRadius: "4px",
                                    px: 2,
                                    py: 1.5,
                                }}
                                key={"test"}
                            >
                                <Typography component="span">Available Balance</Typography>
                                <Typography component="span" sx={{ marginLeft: 10 }}>{solanaTokenBalance}</Typography>
                            </Box>
                        </Stack>
                    </Grid>
                    {!isConnected ? !isMetamaskLoading ?
                        <Grid item container xs={12} mt={4} spacing={0} direction="row"
                            justifyContent="center"
                            alignItems="center" >
                            <BgBox onClick={connectWallet}>
                                <Stack spacing={1}>
                                    {haveMetamask ?
                                        <StyledSmallTitle>Connect Metamask</StyledSmallTitle>
                                        :
                                        <StyledSmallTitle>Install Metamask!</StyledSmallTitle>
                                    }
                                </Stack>
                            </BgBox>
                        </Grid>
                        :
                        <Grid item xs={12} mt={4} sx={{ textAlign: 'center' }}>
                            <CircularProgress />
                        </Grid>
                        :
                        <> </>
                    }
                    {
                        (isConnected && !burnInProgress && !claimTxInProgress) &&
                        <Grid item xs={12} sm={12} mt={4}>
                            <Box sx={{ px: 0 }}>
                                <Stack spacing={2} sx={{ justifyContent: 'center', textAlign: 'center' }}>
                                    <Typography
                                        sx={{
                                            color: "common.gray",
                                            fontWeight: 300,
                                            fontSize: "1rem",
                                        }}
                                    >
                                        Connected address:
                                    </Typography>
                                    <Typography sx={{ fontSize: "0.9rem" }} noWrap>
                                        {accountAddress}
                                    </Typography>
                                </Stack>
                            </Box>
                        </Grid>
                    }
                    {
                        displayInput &&
                        <Grid item container xs={12} mt={2} spacing={1} direction="row"
                            justifyContent="center"
                            alignItems="center"
                            sx={{
                                px: 2,
                            }}
                        >
                            <Grid item xs={12} sm={12} md={3} >
                                <TextField
                                    fullWidth
                                    label={"Input amount"}
                                    error={formErrors.inputAmount.length !== 0 ? true : false}
                                    helperText={<>{formErrors.inputAmount}</>}
                                    value={inputAmount}
                                    type="number"
                                    inputProps={{step:"0.01"}}
                                    onChange={e => setInputAmount(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={4} sm={2} md={1} sx={{ flexDirection: 'row-reverse' }}>
                                <Button
                                    component={Button}
                                    size="large"
                                    variant="outlined"
                                    color="primary"
                                    disabled={+inputAmount < 0 || +inputAmount > solanaTokenBalance}
                                    sx={{
                                        width: "100%",
                                        height: 52,
                                        mb: 0.5
                                    }}
                                    onClick={handleBurn}
                                >
                                    Burn
                                </Button>
                            </Grid>
                        </Grid>
                    }
                    {
                        burnInProgress &&
                        <>
                            <Grid item xs={12} mt={4} sx={{ textAlign: 'center' }}>
                                <Typography sx={{ fontSize: "0.9rem" }} noWrap>
                                    Awaiting SOL blockchain confirmation!
                                </Typography>
                            </Grid>
                            <Grid item xs={12} mt={4} sx={{ textAlign: 'center' }}>
                                <CircularProgress />
                            </Grid>
                            <Grid item xs={12} mt={4} sx={{ textAlign: 'center' }}>
                                <Typography sx={{ fontSize: "1.1rem" }} noWrap>
                                    Do NOT leave this page!
                                </Typography>
                            </Grid>
                        </>
                    }
                    {
                        claimTxInProgress &&
                        <>
                            <Grid item xs={12} mt={4} sx={{ textAlign: 'center' }}>
                                <Typography sx={{ fontSize: "0.9rem" }} noWrap>
                                    Awaiting ETH blockchain confirmation!
                                </Typography>
                            </Grid>
                            <Grid item xs={12} mt={4} sx={{ textAlign: 'center' }}>
                                <CircularProgress />
                            </Grid>
                            <Grid item xs={12} mt={4} sx={{ textAlign: 'center' }} px="30%">
                                <Typography sx={{ fontSize: "0.9rem" }}>
                                    After confirming the metamask transaction, it will be safe to leave this page. The funds will arrive in your account as soon as the claim transaction will be confirmed on the network.
                                </Typography>
                            </Grid>
                        </>
                    }
                    {
                        claimInProgress &&
                        <>
                            <Grid item container xs={12} mt={4} spacing={0} direction="row"
                                justifyContent="center"
                                alignItems="center" >
                                <Button
                                    onClick={handleClaim}
                                    component={Link}
                                    size="large"
                                    variant="outlined"
                                    color="success"
                                    sx={{
                                        textAlign: 'center',
                                        lineHeight: 1.5,
                                        width: 200,
                                        maxWidth: "100%",
                                        height: 60,
                                        mb: [3, 3, 0],
                                    }}
                                >
                                    Claim <br /> {(+inputAmount / 3).toFixed(2)} PRS
                                </Button>
                            </Grid>
                            <Grid item xs={12} mt={4} sx={{ textAlign: 'center' }}>
                                <Typography sx={{ fontSize: "1.1rem" }} noWrap>
                                    Do NOT leave this page!
                                </Typography>
                            </Grid>
                        </>
                    }
                    {
                        displaySuccess &&
                        <Grid item xs={12} mt={4} sx={{ textAlign: 'center' }}>
                            <Typography sx={{ fontSize: "1.2rem" }} noWrap>
                                Transaction confirmed!
                            </Typography>
                        </Grid>
                    }
                    {
                        displayError === "refresh" &&
                        <>
                        <Grid item xs={12} mt={4} sx={{ textAlign: 'center' }}>
                            <Typography sx={{ fontSize: "1.2rem" }} noWrap>
                                An error has occured! No tokens have been burnt!
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6} mt={1}>
                        <Typography sx={{ fontSize: "1rem", textAlign: "left" }} mt={4}>
                                &#8226; The interaction most probably failed due to the network being congested
                            </Typography>
                            <Typography sx={{ fontSize: "1rem", textAlign: "left" }} mt={1}>
                                &#8226; No tokens have been burnt! This means you can safely <a style={{fontFamily: FONTS.POPPINS, fontWeight:800, textDecoration:'none'}} href="/swap"> refresh the page</a> <span style={{fontFamily: FONTS.POPPINS, fontWeight:600}}> and start over</span>
                            </Typography>
                            <Typography sx={{ fontSize: "1rem", textAlign: "left" }} mt={1}>
                                &#8226; If the problem persists, join our <a style={{fontFamily: FONTS.POPPINS, fontWeight:800, textDecoration:'none'}} href="https://discord.com/invite/perseusfintech" target="_blank">  Discord</a> and create a ticket in the support channel. Make sure you mention the admins the state <span style={{fontFamily: FONTS.POPPINS, fontWeight:600}}> "NOT BURNT"</span> 
                            </Typography>
                        </Grid>
                        </>
                    }
                    {
                        displayError === "burned" &&
                        <>
                        <Grid item xs={12} mt={4} sx={{ textAlign: 'center' }}>
                            <Typography sx={{ fontSize: "1.2rem" }} noWrap>
                                An error has occured! Your tokens have already been burnt!
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6} mt={1}>
                            <Typography sx={{ fontSize: "1rem", textAlign: "left" }} mt={4}>
                                &#8226; The interaction most probably failed due to the network being congested 
                                </Typography>
                            <Typography sx={{ fontSize: "1rem", textAlign: "left" }} mt={1}>
                                &#8226; You will see that your balance decreased, but <span style={{fontFamily: FONTS.POPPINS, fontWeight:600}}> don't worry! We will help you claim your PRS on the Ethereum Network </span>
                            </Typography>
                            <Typography sx={{ fontSize: "1rem", textAlign: "left" }} mt={1}>
                                &#8226; Join our <a style={{fontFamily: FONTS.POPPINS, fontWeight:800, textDecoration:'none'}} href="https://discord.com/invite/perseusfintech" target="_blank"> Discord</a> and create a ticket in the support channel. Make sure you mention the admins the state <span style={{fontFamily: FONTS.POPPINS, fontWeight:600}}>"BURNT"</span>
                            </Typography>
                        </Grid>
                        </>
                    }
                </Grid>
            </Grid>
        </Grid>
    );
}

export default SwapBox;
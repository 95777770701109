import { Box, Grid, Typography } from "@mui/material";
import { getStrapiMedia } from "../../../lib/theme/media";

export type PortfolioNftItemsGridPropsType = {
  items?: any[];
};

const PortfolioNftItemsGrid: React.FC<PortfolioNftItemsGridPropsType> = ({
  items,
}) => {
  // *************** RENDER *************** //
  return (
    <Grid container spacing={[2, 2, 4]}>
      {items &&
        items.map((item) => {
          const { attributes } = item;
          const { name, date, image, solPrice } = attributes ?? {};
          return (
            <Grid item xs={12} sm={6} md={3} key={date}>
              <Box
                sx={{
                  border: "1px solid",
                  borderImageSlice: 1,
                  borderImageSource:
                    "linear-gradient(168.82deg, #FB37FF 1.7%, rgba(155, 111, 238, 0) 27.12%, rgba(123, 127, 234, 0) 61.28%, #1BB2DE 99.52%)",
                  backdropFilter: "blur(642.519px)",
                  bgcolor: "#000",
                }}
              >
                {image && (
                  <img
                    src={getStrapiMedia(image)}
                    alt={`${name}`}
                    style={{ width: "100%", height: "auto" }}
                  />
                )}
                <Box sx={{ px: [2, 2, 2.5], py: 1.5 }}>
                <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography component="span">{name}</Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      component="span"
                      sx={{
                        fontSize: "1rem",
                        fontWeight: 300,
                      }}
                    >
                      Date:
                    </Typography>
                    <Typography component="span">{date}</Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      component="span"
                      sx={{
                        fontSize: "1rem",
                        fontWeight: 300,
                      }}
                    >
                      Price:
                    </Typography>
                    <Typography component="span">{solPrice} SOL</Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          );
        })}
    </Grid>
  );
};

export default PortfolioNftItemsGrid;

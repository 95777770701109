import { SvgIconProps, SvgIcon } from "@mui/material";

const DiscordIcon: React.VFC<SvgIconProps> = (props) => {
  // *************** RENDER *************** //
  return (
    <SvgIcon viewBox="0 0 16 16" {...props}>
      <path d="M13.545 2.995A13.33 13.33 0 0010.288 2a.05.05 0 00-.052.024c-.14.246-.296.567-.406.82a12.377 12.377 0 00-3.658 0 8.24 8.24 0 00-.411-.82A.051.051 0 005.708 2a13.32 13.32 0 00-3.256.994.047.047 0 00-.022.018C.356 6.062-.213 9.037.066 11.974a.053.053 0 00.021.037 13.355 13.355 0 003.995 1.987.052.052 0 00.056-.017c.308-.414.583-.85.818-1.31a.05.05 0 00-.028-.069 8.785 8.785 0 01-1.248-.585.05.05 0 01-.005-.083c.084-.063.168-.127.248-.192a.05.05 0 01.052-.007c2.618 1.177 5.453 1.177 8.04 0a.05.05 0 01.053.007c.08.065.164.13.248.191a.05.05 0 01-.004.084c-.398.23-.813.423-1.248.585a.05.05 0 00-.03.028.05.05 0 00.002.041c.24.459.515.895.817 1.309a.051.051 0 00.056.019 13.307 13.307 0 004.001-1.988.051.051 0 00.022-.036c.333-3.396-.559-6.347-2.366-8.962a.04.04 0 00-.021-.018zm-8.198 7.19c-.788 0-1.438-.712-1.438-1.586 0-.875.637-1.587 1.438-1.587.807 0 1.45.718 1.438 1.587 0 .874-.637 1.586-1.438 1.586zm5.317 0c-.789 0-1.438-.712-1.438-1.586 0-.875.636-1.587 1.438-1.587.806 0 1.45.718 1.438 1.587 0 .874-.631 1.586-1.438 1.586z"></path>
    </SvgIcon>
  );
};

export default DiscordIcon;

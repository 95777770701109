import axios from 'axios';
import ethers from "ethers";

type SignResponse = {
    ethReceiver: string | qs.ParsedQs | string[] | qs.ParsedQs[] | undefined,
    solanaTxId: string | qs.ParsedQs | string[] | qs.ParsedQs[] | undefined,
    ethPrsAmount: ethers.BigNumber | string,
    backendSignature: string
};

function sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export async function sign(ethReceiver: string, solanaSigner: string, solanaSignature: string, solanaTxId: string) {
    try {
        const backendUrl = process.env.REACT_APP_BACKEND_URL ? process.env.REACT_APP_BACKEND_URL : 'http://localhost:5000/sign';
        const { data, status } = await axios.get<SignResponse>(
            backendUrl,
            {
                headers: {
                    Accept: 'application/json',
                },
                params: {
                    ethReceiver: ethReceiver,
                    solanaSigner: solanaSigner,
                    solanaSignature: solanaSignature,
                    solanaTxId: solanaTxId
                }
            },
        );
        return data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            return error.message;
        } else {
            console.log('unexpected error: ', error);
            return 'An unexpected error occurred';
        }
    }
}


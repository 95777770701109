import Web3 from "web3";
import ethers from "ethers";
import { AbiItem } from "web3-utils";
import ethBridgeAbi from "../abi/ethreum_bridge.json";

type SignResponse = {
    ethReceiver: string | qs.ParsedQs | string[] | qs.ParsedQs[] | undefined,
    solanaTxId: string | qs.ParsedQs | string[] | qs.ParsedQs[] | undefined,
    ethPrsAmount: ethers.BigNumber | string,
    backendSignature: string
};

export async function claimEth(params: SignResponse) {
    const web3 = new Web3(window.ethereum);
    await window.ethereum.enable();
    if (window.ethereum.networkVersion !== 1) {
        try {
            await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: web3.utils.toHex(1) }]
            });
            const accounts = await window.ethereum.request({
                method: 'eth_requestAccounts',
            });
            const ethBridgeAddress = process.env.REACT_APP_ETH_BRIDGE_ADDRESS ? process.env.REACT_APP_ETH_BRIDGE_ADDRESS : "0x246099F3f768D9e61c51f476C0F7F8cC876dEc0f";
            const ethBridge = new web3.eth.Contract(ethBridgeAbi as AbiItem[], ethBridgeAddress);
            const ethResponse = await ethBridge.methods.claim(params.solanaTxId, params.ethPrsAmount, params.backendSignature).send({ from: accounts[0] });
            return ethResponse;
        } catch (err: any) {
            // This error code indicates that the chain has not been added to MetaMask
            if (err.code === 4902) {
                await window.ethereum.request({
                    method: 'wallet_addEthereumChain',
                    params: [
                        {
                            chainName: process.env.REACT_APP_ETHEREUM_CHAIN_NAME ? process.env.REACT_APP_ETHEREUM_CHAIN_NAME : 'Rinkeby',
                            chainId: web3.utils.toHex(process.env.REACT_APP_ETHEREUM_CHAIN_ID ? process.env.REACT_APP_ETHEREUM_CHAIN_ID : 4),
                            nativeCurrency: { name: 'ETHEREUM', decimals: 18, symbol: 'ETH' },
                            rpcUrls: [process.env.REACT_APP_ETHEREUM_RPC_PROVIDER ? process.env.REACT_APP_ETHEREUM_RPC_PROVIDER : 'https://rpc.ankr.com/eth_rinkeby']
                        }
                    ]
                });
                await claimEth(params);
            }
            else {
                return null;
            }
        }
    }


}
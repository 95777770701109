import TopFillerBox from "../components/Reusable/TopFillerBox";
import SectionHeader from "../components/Reusable/SectionHeader";
import SwapBox from "../components/Swap/SwapBox";
import { sectionMarginBottom } from "../constants/styleConstants";
import usePageData from "../hooks/usePageData";

export type SwapPagePropsType = {
    children?: any;
};

const SwapPage: React.FC<SwapPagePropsType> = ({ children }) => {
    const { SeoComponent, pageData } = usePageData("about-us-page");
    const { sectionHeader } = pageData ?? {};
    return (
        <>
            {SeoComponent}
            <TopFillerBox />
            <SectionHeader
                {...sectionHeader}
                sx={{ maxWidth: 430, m: "0 auto", pt: sectionMarginBottom,}}
            />
            <SwapBox />
        </>
    );
}

export default SwapPage;